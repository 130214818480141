import React, { useEffect } from 'react';
import Loader from '../../include/Loader';
import Menu from '../../include/Menu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ColDetail from '../../general/ColDetail';
import ShowImage from '../../general/ShowImage';

export default function DriversDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  var data = location.state;

  useEffect(() => {
    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              {/* <a href="javascript:;">User List</a> */}
              <NavLink to={'/drivers-list'}>Drivers List</NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">
              Drivers Details
            </li>
          </ol>
          <h1 className="page-header">
            <i
              className="fa fa-arrow-left edit"
              onClick={() => navigate('/drivers-list')}
              style={{
                cursor: 'pointer',
                fontSize: '20px',
                marginTop: '7px',
                marginRight: '10px',
              }}
            ></i>
            Drivers Details
          </h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
              marginTop: '10px',
            }}
          >
            <div className="row RestName p-5" style={{ borderRadius: '20px' }}>
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Details of {data?.first_name}
                </span>
              </div>
            </div>
            <br />

            <div className="row p-5">
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  General
                </span>
              </div>
            </div>

            <div className="row">
              <ColDetail title="First Name" data={data?.first_name} />
              <ColDetail title="Last Name" data={data?.last_name} />
              <ColDetail title="Phone No" data={data?.phone_number} />
              <ColDetail title="Email" data={data?.email} />
              <ColDetail title="Address" data={data?.address} />
              <ColDetail title="other Address" data={data?.address2} />
              <ColDetail title="City" data={data?.city} />
              <ColDetail title="State " data={data?.state} />
              <ColDetail title="Zip Code " data={data?.zip_code} />
              <ColDetail title="Country " data={data?.country} />
              <ColDetail title="Country Code " data={data?.country_code} />
              <ColDetail title="Security Code " data={data?.security_code} />
            </div>
            <div className="row">
              <ShowImage
                title="Driver Image"
                data={data?.image}
                url={
                  data?.image && data?.image.startsWith('http') ? data?.image :
                    `https://api.teleportanything.com/uploads/${data?.image}`
                }
                // url={data?.image}
                alt="DriverImage"
                dummyUrl={'/assets/img/icon/customer.png'}
              />
              {/* <ShowImage
                title="Insurance Doc"
                data={data?.insurance_doc}
                url={
                  data?.insurance_doc && data?.insurance_doc.startsWith('http') ? data?.insurance_doc :
                    `https://api.teleportanything.com/uploads/${data?.insurance_doc}`
                }
                alt="InsuranceDoc"
                dummyUrl={'/assets/img/icon/doc.jpg'}
              /> */}
            </div>

            <br />
            <br />
            <div className="row p-5">
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Information about License
                </span>
              </div>
            </div>

            <div className="row">
              <ColDetail title="License No" data={data?.license_no} />
              {/* <ColDetail title="License Plate " data={data?.license_plate} /> */}
              <ColDetail title="License State " data={data?.license_state} />
              <ColDetail title="Card No " data={data?.card_no} />
              <ColDetail title="Expiry Date " data={data?.exp_date} />
              <ColDetail
                title="Delivery Radius "
                data={data?.preferred_delivery_radius}
              />
              <ColDetail
                title="Customer Radius "
                data={data?.add_custom_radius}
              />
            </div>
            {/* <div className="row">
              <ShowImage
                title="License's Front Image"
                data={data?.license_image_front}
                url={
                  data?.license_image_front && data?.license_image_front.startsWith('http') ? data?.license_image_front :
                    `https://api.teleportanything.com/uploads/${data?.license_image_front}`
                }
                // url={data?.license_image_front}
                alt="LicenseImage"
                dummyUrl={'/assets/img/icon/doc.jpg'}
              />
              <ShowImage
                title="License's Back Image"
                data={data?.license_image_back}
                url={
                  data?.license_image_back && data?.license_image_back.startsWith('http') ? data?.license_image_back :
                    `https://api.teleportanything.com/uploads/${data?.license_image_back}`
                }
                // url={data?.license_image_back}
                alt="LicenseImage"
                dummyUrl={'/assets/img/icon/doc.jpg'}
              />
            </div> */}

            <br />
            <br />
            <div className="row p-5">
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Information about Vehicle
                </span>
              </div>
            </div>

            <div className="row">
              <ColDetail title="Vehicle Type" data={data?.vehicle_type} />
              <ColDetail
                title="Vehicle Type Other "
                data={data?.vehicle_type_other}
              />
              <ColDetail
                title="Vehicle License State "
                data={data?.vehicle_license_plate_state}
              />
            </div>
            <br />
            <div className="row">
              <ShowImage
                title="Vehicle Image"
                data={data?.vehicle_photo}
                url={
                  data?.vehicle_photo && data?.vehicle_photo.startsWith('http') ? data?.vehicle_photo :
                    `https://api.teleportanything.com/uploads/${data?.vehicle_photo}`
                }
                alt="VehicleImage"
                dummyUrl={'/assets/img/icon/doc.jpg'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
