import React, { useEffect } from 'react';
import Loader from '../../include/Loader';
import Menu from '../../include/Menu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ColDetail from '../../general/ColDetail';
import ShowImage from '../../general/ShowImage';

export default function JobsDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  var data = location.state;

  useEffect(() => {
    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              {/* <a href="javascript:;">User List</a> */}
              <NavLink to={'/jobs-list'}>Job List</NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">
              Job Details
            </li>
          </ol>
          <h1 className="page-header">
            <i
              className="fa fa-arrow-left edit"
              onClick={() => navigate('/jobs-list')}
              style={{
                cursor: 'pointer',
                fontSize: '20px',
                marginTop: '7px',
                marginRight: '10px',
              }}
            ></i>
            Job Details
          </h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
              marginTop: '10px',
            }}
          >
            <div className="row RestName p-5" style={{ borderRadius: '20px' }}>
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Details of {data?.item_description}
                </span>
              </div>
            </div>
            <br />
            <div className="row p-5">
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Items
                </span>
              </div>
            </div>
            {(data.items.length > 0)
              ?
              <>
                {data.items.map((item, index) =>
                  <>
                    {index + 1}
                    <div className="row">

                      <ColDetail title="Item Name" data={item?.name} />
                      <ColDetail title="Qty" data={item?.qty} />
                      <ColDetail title="Item Value" data={data?.item_value} />
                      <ShowImage
                        title="Item Image"
                        data={item?.imageUrl}
                        url={
                          item?.imageUrl && item?.imageUrl.startsWith('http') ? item?.imageUrl :
                            `https://api.teleportanything.com/uploads/${item?.imageUrl}`
                        }
                        // url={data?.imageUrl}
                        alt="itemImage"
                        dummyUrl={'/assets/img/icon/customer.png'}
                      />
                    </div>
                  </>
                )}

              </>
              :
              <div className="row p-5">
                <div className="mx-auto ">
                  <span style={{ fontSize: '18px', fontWeight: '700' }}>
                    No Items Found
                  </span>
                </div>
              </div>
            }

            <br />
            <br />

            <div className="row p-5">
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Customer
                </span>
              </div>
            </div>

            <div className="row">
              <ColDetail title="First Name" data={data?.user_id?.first_name} />
              <ColDetail title="Last Name" data={data?.user_id?.last_name} />
              <ColDetail title="Country Code " data={data?.user_id?.country_code} />
              <ColDetail title="Phone No" data={data?.user_id?.phone_number} />
              <ColDetail title="Email" data={data?.user_id?.email} />
              <ColDetail title="Address" data={data?.user_id?.address} />
              <ColDetail title="other Address" data={data?.user_id?.address2} />
              <ColDetail title="City" data={data?.user_id?.city} />
              <ColDetail title="State " data={data?.user_id?.state} />
              <ColDetail title="Zip Code " data={data?.user_id?.zip_code} />
              <ColDetail title="Country " data={data?.user_id?.country} />
              <ColDetail title="Security Code " data={data?.user_id?.security_code} />
            </div>
            <div className="row">
              <ShowImage
                title="Customer Image"
                data={data?.user_id?.image}
                url={
                  data?.user_id?.image && data?.user_id?.image.startsWith('http') ? data?.user_id?.image :
                    `https://api.teleportanything.com/uploads/${data?.user_id?.image}`
                }
                // url={data?.user_id?.image}
                alt="CustomerImage"
                dummyUrl={'/assets/img/icon/customer.png'}
              />
              {/* <ShowImage
                title="Insurance Doc"
                data={data?.insurance_doc}
                url={
                  data?.insurance_doc && data?.insurance_doc.startsWith('http') ? data?.insurance_doc :
                    `https://api.teleportanything.com/uploads/${data?.insurance_doc}`
                }
                // url={`https://api.teleportanything.com/uploads/${data?.insurance_doc}`}
                alt="InsuranceDoc"
                dummyUrl={'/assets/img/icon/doc.jpg'}
              /> */}
            </div>


            <div className="row p-5">
              <div className="mx-auto ">
                <span style={{ fontSize: '18px', fontWeight: '700' }}>
                  Hire Driver
                </span>
              </div>
            </div>

            <div className="row">
              <ColDetail title="First Name" data={data?.hire_driver_id?.first_name} />
              <ColDetail title="Last Name" data={data?.hire_driver_id?.last_name} />
              <ColDetail title="Country Code " data={data?.hire_driver_id?.country_code} />
              <ColDetail title="Phone No" data={data?.hire_driver_id?.phone_number} />
              <ColDetail title="Email" data={data?.hire_driver_id?.email} />
              <ColDetail title="Address" data={data?.userhire_driver_id_id?.address} />
              <ColDetail title="other Address" data={data?.hire_driver_id?.address2} />
              <ColDetail title="City" data={data?.hire_driver_id?.city} />
              <ColDetail title="State " data={data?.hire_driver_id?.state} />
              <ColDetail title="Zip Code " data={data?.hire_driver_id?.zip_code} />
              <ColDetail title="Country " data={data?.hire_driver_id?.country} />
              <ColDetail title="Security Code " data={data?.hire_driver_id?.security_code} />
            </div>

            <div className="row">
              <ShowImage
                title="Driver Image"
                data={data?.hire_driver_id?.image || ''}
                url={
                  data?.hire_driver_id?.image ? data?.hire_driver_id?.image.startsWith('http') ? data?.hire_driver_id?.image :
                    `https://api.teleportanything.com/uploads/${data?.hire_driver_id?.image}` : null
                }
                // url={data?.user_id?.image}
                alt="DriverImage"
                dummyUrl={'/assets/img/icon/customer.png'}
              />
              {/* <ShowImage
                title="Insurance Doc"
                data={data?.insurance_doc}
                url={
                  data?.insurance_doc && data?.insurance_doc.startsWith('http') ? data?.insurance_doc :
                    `https://api.teleportanything.com/uploads/${data?.insurance_doc}`
                }
                // url={`https://api.teleportanything.com/uploads/${data?.insurance_doc}`}
                alt="InsuranceDoc"
                dummyUrl={'/assets/img/icon/doc.jpg'}
              /> */}
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
