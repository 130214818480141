import { TableHeader, Pagination, Search } from '../Table';
import React, { useState, useEffect, useMemo } from 'react';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import { Dropdown, Table } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HistoryIcon from '@material-ui/icons/History';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../include/Spinner';

export default function PayoutRequestList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [Balance, setBalance] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const [Loading, setLoading] = useState(true);
  const userHeader = [
    {
      name: 'Sr. No.',
      field: 'id',
      sortable: false,
    },
    {
      name: 'Driver Name',
      // field: "",
      sortable: false,
    },
    {
      name: 'Driver Email',
      // field: "",
      sortable: false,
    },
    {
      name: 'Driver Phone No',
      // field: "",
      sortable: false,
    },
    {
      name: 'Total Earn',
      // field: "",
      sortable: false,
    },
    {
      name: 'Total Payout',
      // field: "",
      sortable: false,
    },
    {
      name: 'Requested Amount',
      // field: "to",
      sortable: false,
    },
    {
      name: 'Requested At',
      sortable: false,
    },
    {
      name: 'Status',
      sortable: false,
    },
    {
      name: 'Action',
      sortable: false,
    },
  ];

  const commentsData = useMemo(() => {
    let computedComments = entity;

    if (search) {

      computedComments = computedComments.filter(
        (payouts) =>
          payouts?.driver_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.driver_email?.toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.phone_number?.toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.total_earn?.toString().toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.total_payout?.toString().toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.requested_amount?.toString().toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.createdAt?.toString().toLowerCase()?.includes(search?.toLowerCase()) ||
          payouts?.status == 0 && "pending".includes(search?.toLowerCase()) ||
          payouts?.status == 1 && "accepted".includes(search?.toLowerCase()) ||
          payouts?.status == 2 && "declined".includes(search?.toLowerCase())


      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, limit, entity]);

  useEffect(() => {
    getDriverList();
    document.getElementById('page-loader').style.display = 'none';
    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  const getDriverList = () => {
    const myurl = 'https://api.teleportanything.com/api/admin/payout-request-list';
    //const myurl = 'http://localhost:3000/api/admin/payout-request-list';
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('status', filterData.status);
    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then(async (response) => {
        setDisable(false);
        if (response?.data?.sucecess) {
          let data = await response?.data?.data?.map((e, i) => {
            return { ...e, sr_no: i + 1 };
          });
          setEntity(data);

          setBalance(response?.data?.balance);
        } else {
          toast.error('Something went wrong');
        }
        setLoading(false)
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error("Errors", error);
        setLoading(false)
      });
  };


  const actionHandler = (e, id, item_description, status) => {
    e.target.blur();
    let msg;
    if (status == 1)
      msg = "Are you sure you want to accept the payout request?";
    else if (status == 2)
      msg = "Are you sure you want to decline the payout request?";
    if (window.confirm(msg)) {

      const myurl = "https://api.teleportanything.com/api/admin/accept-payout-request";
      //const myurl = "http://localhost:3000/api/admin/accept-payout-request";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('request_id', id);
      bodyFormData.append('status', status);

      setLoading(true)

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        if (response.data.sucecess == true) {
          getDriverList();
          toast.success(status == 1 ? "Request accepted successfully" : "Request declined successfully");
        }
        else {
          toast.error(response?.data?.message);
        }
        setLoading(false)

      }).catch((error) => {
        toast.error(error.response.data.message || "Something going wrong");
        setLoading(false)

      })
    }
  }

  const [disable, setDisable] = useState(false);
  const [filterData, setFilterData] = useState({
    status: ''
  });
  const handleInput = (e) => {
    ;
    const basicData = { ...filterData };
    basicData[e.target.name] = e.target.value;
    setFilterData(basicData);
  }
  const handleSearch = (e) => {
    setDisable(true);
    getDriverList();
  }

  const handleReset = () => {
    window.location.reload();
  }

  const switchStatus = (status) => {

    switch (status) {
      case 0:
        return <span class="text-warning">Pending</span>;
      case 1:
        return <span class="text-success">Accepted</span>;
      case 2:
        return <span class="text-danger">Declined</span>;
      case 3:
        return <span class="text-danger">Insufficient Balance</span>;
    }
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              Payout Request Management
            </li>
          </ol>
          <h1 className="page-header">Payout Request Management</h1>

          {/* <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
                <div className="col-12 text-center">
                <h6 style={{textAlign:"left"}}>Filter For Jobs Management:</h6>
                  <div className="row">
                   
                      <div className="col-md-3 form-group">
                        <select className="form-control" name="status" onChange={handleInput} value={filterData.status}>
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                                <option value="2">Draft</option>
                                <option value="3">Completed</option>
                            </select>
                      </div>

                      <div className="col-md-3" style={{ textAlign: "left"}}>
                        <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor:"pointer" }} disabled={disable} onClick={handleSearch} >{disable ? 'Processing...' : 'Search'}</button>
                        <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor:"pointer", marginLeft: "10px" }} onClick={handleReset} >Reset</button>
                      </div>
                  </div>
                </div>
              </div>
            </div> */}


          <br />

          {!Loading ? (

            <div
              style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '20px',
                marginTop: '10px',
              }}
            >
              <div className="row w-100">
                <div className="mb-3 col-12 text-center">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-sm-4 col-12 mb-4">
                      <div className="ml-0">
                        <div className="d-flex">
                          <h5 className="mt-2 mr-1">Search: </h5>
                          <Search
                            onSearch={(value) => {
                              setSearch(value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                      <div className='col-sm-6'>

                        <b>Available for payout&nbsp;:</b>

                        {
                          Balance?.available?.map((data) => {
                            return <>
                              <br />
                              {Object.keys(data).map((key) => {

                                return <>{key} : {data[key]} <br /></>;
                              })}</>
                          })}
                      </div>
                      <div className='col-sm-6'>

                        <b>Pending Amount&nbsp;: </b>

                        {
                          Balance?.pending?.map((data) => {
                            return <>
                              <br />
                              {Object.keys(data).map((key) => {

                                return <>{key} : {data[key]} <br /></>;
                              })}</>
                          })}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                      <div
                        style={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: '300',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                        className="align-self-center"
                      >
                        <b>Rows per page :&nbsp;</b>
                      </div>
                      <div className="align-self-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            style={{
                              cursor: 'auto',
                              backgroundColor: 'white',
                              borderColor: '#d5dbe0',
                              paddingBottom: '3px',
                              paddingTop: '3px',
                            }}
                          >
                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {limit !== 10 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(10);
                                  }}
                                >
                                  10
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 20 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(20);
                                  }}
                                >
                                  20
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 30 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(30);
                                  }}
                                >
                                  30
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 50 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(50);
                                  }}
                                >
                                  50
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <TableHeader
                              headers={userHeader}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            />
                          </thead>
                          <tbody>

                            {commentsData.map((e, i) => (
                              <tr>
                                <>
                                  <td>{e?.sr_no}</td>
                                  <td>{e?.driver_name}</td>
                                  <td>{e?.driver_email}</td>
                                  <td>{e?.phone_number}</td>
                                  <td>{e?.total_balance}</td>
                                  <td>{e?.total_payout}</td>
                                  <td>{e?.requested_amount}</td>
                                  <td>{e?.createdAt}</td>
                                  <td>{switchStatus(e.status)}
                                  </td>
                                  <td >


                                    {
                                      e.is_stripe_account_setup ?
                                        e.status == 0 ?
                                          <div style={{ display: "flex", gap: "3px" }}>
                                            <button className="btn btn-success btn-sm" onClick={(evt) => actionHandler(evt, e?.request_id, e?.item_des, 1)}>Yes</button>
                                            <button className='btn btn-danger btn-sm' onClick={(evt) => actionHandler(evt, e?.request_id, e?.item_des, 2)}>No</button>
                                          </div>
                                          : "-"
                                        : <span class="text-danger">Stripe account is not setup</span>

                                    }

                                  </td>
                                </>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {commentsData.length == 0 ? (
                          <div className="row d-flex justify-content-center w-100">
                            <div className="mx-auto  d-flex justify-content-center w-100">
                              <img
                                src="./assets/img/icon/no-location.png"
                                className="form-img__img-preview"
                                style={{ width: '100px', height: '100px' }}
                                alt=""

                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                    style={{
                      overflowX: 'auto',
                    }}
                  >
                    <Pagination
                      total={totalItems}
                      itemsPerPage={limit}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        <Footer />
      </div>
    </>
  );
}
