import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import axios from 'axios';

export default function Profile() {
  const [change, setChange] = useState(true);

  useEffect(() => {
    document.getElementById('page-loader').style.display = 'none';
    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, [change]);

  const [img, setImg] = useState({
    src: '',
    alt: '',
  });
  const [profileInfo, setProfileInfo] = useState({
    name: localStorage.getItem('DM_Admin_NAME'),
    image: localStorage.getItem('DM_Admin_IMAGE'),
  });
  const [isPicUpload, setIsPicUpload] = useState(false);
  const [errors, setErrors] = useState({});
  const [passErrors, setPassErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [pictureName, setPictureName] = useState({});
  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };
  const [password, setPassword] = useState(initialValues);
  const handleImg = (e) => {
    if (e?.target?.files[0]) {
      setImg({
        src: URL.createObjectURL(e?.target?.files[0]),
        alt: e?.target?.files[0].name,
      });
      setDisable(true);
      setIsPicUpload(true);

      // const myurl = `https://api.teleportanything.com/upload-img`;
      // const myurl = `https://api.teleportanything.com/upload-imgage`;
      const myurl = `https://api.teleportanything.com/upload-image`;
      var bodyFormData = new FormData();
      bodyFormData.append('image', e?.target?.files[0]);
      bodyFormData.append('type', "common");

      axios({
        method: 'post',
        url: myurl,
        data: bodyFormData,
      })
        .then((result) => {

          if (result?.data?.sucecess == true) {
            // setPictureName(result?.data?.data?.filepath_url);
            setPictureName(result?.data?.data?.url);
            setDisable(false);
          } else {
            setIsPicUpload(false);
            setPictureName('');
            setDisable(false);
          }
        })
        .catch((error) => {
          console.error("Errors", error);
          setIsPicUpload(false);
          setPictureName('');
          setDisable(false);
        });
    }
  };
  const InputEvent = (e) => {
    const newProfileInfo = { ...profileInfo };
    newProfileInfo[e.target.name] = e.target.value;
    setProfileInfo(newProfileInfo);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { name } = profileInfo;
      const myurl = 'https://api.teleportanything.com/api/admin/update-profile';
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('auth_code', 'Teleport#Usr$&$Driver#MD');
      bodyFormData.append('name', name);
      bodyFormData.append(
        'image',
        isPicUpload
          ? pictureName
          : localStorage.getItem('DM_Admin_IMAGE')
      );
      axios({
        method: 'post',
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
        .then((response) => {
          if (response?.data?.sucecess == true) {
            localStorage.setItem('DM_Admin_NAME', name);
            localStorage.setItem('DM_Admin_IMAGE', response?.data?.data?.image);
            // localStorage.setItem('DM_Admin_IMAGE',`https://api.teleportanything.com/uploads/${pictureName}`);
            setDisable(false);
            setIsPicUpload(false);
            setPictureName({});
            toast.success('Profile Updated Successfully');
            setProfileInfo({
              name: name,
              image: response?.data?.data?.image,
            });
          } else {
            setDisable(false);
            setPictureName({});
            setIsPicUpload(false);
          }
        })
        .catch((error) => {
          console.error("Errors", error);
          toast.error('Something went wrong');
          setPictureName({});
          setDisable(false);
          setIsPicUpload(false);
        });
    }
    setChange(!change);
  };

  const validate = () => {
    let input = profileInfo;

    let errors = {};
    let isValid = true;

    if (!input['name']) {
      isValid = false;
      errors['name_err'] = 'Please enter name';
    }

    setErrors(errors);
    return isValid;
  };

  const changePassword = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  };

  const validatePass = () => {
    let input = password;

    let passErrors = {};
    let isValidPass = true;

    if (!input['old_password']) {
      isValidPass = false;
      passErrors['old_password'] = 'Please enter old password.';
    }
    if (!input['new_password']) {
      isValidPass = false;
      passErrors['new_password'] = 'Please enter new password.';
    }
    if (!input['confirm_password']) {
      isValidPass = false;
      passErrors['confirm_password'] = 'Please enter confirm password.';
    }
    if (input['new_password'] != '' && input['confirm_password'] != '') {
      if (input['new_password'] != input['confirm_password']) {
        isValidPass = false;
        passErrors['password_error'] =
          'New password and confirm password are not same.';
      }
    }
    setPassErrors(passErrors);
    return isValidPass;
  };

  const changePass = async (e) => {
    e.preventDefault();
    setDisable1(true);
    if (validatePass()) {
      const myurl = 'https://api.teleportanything.com/api/admin/change-password';
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('auth_code', 'Teleport#Usr$&$Driver#MD');
      bodyFormData.append('old_password', password.old_password);
      bodyFormData.append('new_password', password.new_password);
      bodyFormData.append('confirm_password', password.confirm_password);

      axios({
        method: 'post',
        url: myurl,
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => {
          if (response?.data?.sucecess === true) {
            setDisable1(false);
            toast.success('Password Updated Successfully');
            setPassErrors({});
            setPassword(initialValues);
            setChange(!change);
          } else {
            setDisable1(false);
            toast.error('Invalid Inputs');
            setPassErrors({});
            setChange(!change);
          }
        })
        .catch((error) => {
          console.error("Errors", error);
          setPassword(initialValues);
          setPassErrors({});
          setDisable1(false);
          toast.error('Something went wrong');
          setChange(!change);
        });
    } else {
      setDisable1(false);
    }
  };

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Profile</li>
          </ol>
          <h1 className="page-header">Profile</h1>

          <div className="row">
            <div className="col-xl-6 p-5">
              <div
                className="card "
                style={{
                  height: 'auto',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <div className="mx-auto">
                  <h3 class="card-title mx-auto" style={{ color: '#05ac93' }}>
                    Profile Setting
                  </h3>
                </div>
                <form
                  onSubmit={(e) => submitHandler(e)}
                  className="profileForm"
                >
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Name:
                    </label>

                    <input
                      type="text"
                      className="form-control ml-0"
                      id="exampleInputName"
                      placeholder="Enter name here.."
                      name="name"
                      value={profileInfo.name}
                      onChange={InputEvent}
                    />
                    <div className="text-danger">{errors.name_err}</div>
                  </div>

                  <div className="mb-3">
                    <label for="exampleInputImage">Image: </label>
                    {profileInfo.image != '' ? (
                      <img
                        src={localStorage.getItem('DM_Admin_IMAGE')}
                        className="form-img__img-preview ml-2"
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '10px',
                        }}
                        alt="Profile_Picture"
                      />
                    ) : (
                      <>
                        <img
                          src={'/assets/img/icon/profile-icon.png'}
                          alt="ProfileImage"
                          className="form-img__img-preview ml-2"
                          style={{
                            width: '100px',
                            height: '100px',
                            margin: '10px',
                          }}
                        />
                      </>
                    )}

                    <br />

                    <input
                      type="file"
                      className="form-control imgInput ml-0 mt-1"
                      id="exampleInputImage"
                      onChange={handleImg}
                      accept="image/png, image/gif, image/jpeg"
                    />
                    {img.src != '' ? (
                      <img
                        src={img.src}
                        className="form-img__img-preview mt-1"
                        style={{ width: '84px', height: '84px' }}
                        alt="imgs"
                      />
                    ) : (
                      ''
                    )}
                    <div className="text-danger">{errors.img_err}</div>
                  </div>
                  <button
                    type="submit"
                    className="btn m-r-5 btn-success"
                    disabled={disable}
                  >
                    {disable ? 'Processing...' : 'Submit'}
                  </button>
                  <button
                    type="reset"
                    className="btn btn-dark"
                    value="Reset"
                    onClick={(e) => {
                      setIsPicUpload(false);
                      setImg({ src: '', alt: '' });
                      setChange(!change);
                    }}
                  >
                    Reset
                  </button>
                </form>
              </div>
            </div>

            <div className="col-xl-6 p-5">
              <div
                className="card "
                style={{
                  height: 'auto',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <div className="mx-auto">
                  <h3 class="card-title mx-auto" style={{ color: '#05ac93' }}>
                    Change Password
                  </h3>
                </div>
                <form onSubmit={changePass} className="changePassForm">
                  <div className="mb-3">
                    <label for="exampleInputOldPass">Old Password:</label>
                    <input
                      type="password"
                      className="form-control ml-0"
                      id="exampleInputOldPass"
                      placeholder="Enter Old Password"
                      name="old_password"
                      value={password.old_password}
                      onChange={changePassword}
                    />
                    <div className="text-danger ">
                      {passErrors.old_password}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label for="exampleInputNewPass">New Password:</label>
                    <input
                      type="password"
                      className="form-control ml-0"
                      id="exampleInputNewPass"
                      placeholder="Enter New Password"
                      name="new_password"
                      value={password.new_password}
                      onChange={changePassword}
                    />
                    <div className="text-danger ">
                      {passErrors.new_password}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputConfirmPass">
                      Confirm Password:
                    </label>
                    <input
                      type="password"
                      className="form-control ml-0"
                      id="exampleInputConfirmPass"
                      placeholder="Enter Confirm Password"
                      name="confirm_password"
                      value={password.confirm_password}
                      onChange={changePassword}
                    />
                    <div className="text-danger ">
                      {passErrors.confirm_password}
                    </div>
                    <div className="text-danger ">
                      {passErrors.password_error}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn m-r-5 btn-success"
                    disabled={disable1}
                  >
                    {disable1 ? 'Processing...' : 'Submit'}
                  </button>
                  <button
                    type="reset"
                    className="btn btn-dark"
                    value="Reset"
                    onClick={(e) => {
                      setPassword(initialValues);
                      setPassErrors({});
                    }}
                  >
                    Reset
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
