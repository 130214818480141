import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import { toast } from 'react-toastify';
import axios from 'axios';
import Spinner from '../include/Spinner';

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [entity, setEntity] = useState([]);

  const countData = () => {
    const myurl = 'https://api.teleportanything.com/api/admin/dashboard-widget';
    //const myurl = 'http://localhost:3000/api/admin/dashboard-widget';
    axios({
      method: 'post',
      url: myurl,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then(async (response) => {
        if (response?.data?.sucecess) {
          await setEntity(response?.data?.data);
        } else {
          toast.error('Something went wrong');
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error("Errors", error);
      });
  };
  useEffect(() => {
    countData();
    document.getElementById('page-loader').style.display = 'none';

    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, []);

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">Dashboard</li>
          </ol>
          <h1 className="page-header">Dashboard </h1>

          {/* ====================================== */}
          {entity?.length !== 0 ? (
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-info">
                  <div className="stats-icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Users</h4>
                    <p>{entity?.users}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/users-list">
                      View Detail{' '}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-grey">
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i class="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Drivers</h4>
                    <p>{entity?.drivers}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/drivers-list">
                      View Detail{' '}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-dark">
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i class="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Active Jobs</h4>
                    <p>{entity?.activeJobs || 0}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/jobs-list">
                      View Detail{' '}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-success">
                  <div className="stats-icon">
                    {/* <i className="fa fa-users"></i> */}
                    <i class="fa fa-user-tie"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Completed Jobs</h4>
                    <p>{entity?.completedJobs || 0}</p>
                  </div>
                  <div className="stats-link">
                    <NavLink to="/jobs-list">
                      View Detail{' '}
                      <i className="fa fa-arrow-alt-circle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-md-6">
                  <div className="widget widget-stats bg-orange">
                     <div className="stats-icon"><i className="fa fa-dollar-sign"></i></div>
                     <div className="stats-info">
                        <h4>Total Revenue</h4>
                        <p>1,291,922</p>
                     </div>
                     <div className="stats-link">
                        <a href="javascript:;">View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                     </div>
                  </div>
               </div> */}
              {/* <div className="col-xl-4 col-md-6">
              <div className="widget widget-stats bg-red">
                <div className="stats-icon">
                  <i className="fa fa-shopping-cart"></i>
                </div>
                <div className="stats-info">
                  <h4>Total Orders</h4>
                   <p>{orders}</p> 
                </div>
                <div className="stats-link">
                  <NavLink to="/orders">
                    View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                  </NavLink>
                </div>
              </div>
            </div> */}
            </div>
          ) : (
            <Spinner />
          )}

          {/* ====================================== */}
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
