import React from 'react'
import { NavLink } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

function Footer() {
    const location = useLocation();
    return (
        <>
            <NavLink href={location?.pathname} className="btn btn-icon btn-circle btn-success btn-scroll-to-top fade" data-click="scroll-top"><i className="fa fa-angle-up"></i></NavLink>
        </>
    )
}

export default Footer
