import { TableHeader, Pagination, Search } from '../Table';
import React, { useState, useEffect, useMemo } from 'react';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import { Dropdown, Table } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HistoryIcon from '@material-ui/icons/History';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../include/Spinner';
import moment from 'moment';

export default function ShowUserList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const [Loading, setLoading] = useState(true);
  const userHeader = [
    {
      name: 'Sr. No.',
      field: 'id',
      sortable: false,
    },
    {
      name: 'Image',
      // field: "",
      sortable: false,
    },
    {
      name: 'First Name',
      // field: "",
      sortable: false,
    },
    {
      name: 'Last Name',
      // field: "",
      sortable: false,
    },
    {
      name: 'Email',
      // field: "",
      sortable: false,
    },
    {
      name: 'Phone Number',
      // field: "to",
      sortable: false,
    },

    // {
    //   name: "Gender",
    //   sortable: false,
    // },
    {
      name: 'City',
      sortable: false,
    },
    {
      name: 'Country',
      sortable: false,
    },
    {
      name: 'Created At',
      sortable: false,
    },
    {
      name: 'Details',
      sortable: false,
    },
    {
      name: 'Action',
      sortable: false,
    },
  ];

  // const commentsData = useMemo(() => {
  //   let computedComments = entity;

  //   if (search) {
  //     computedComments = computedComments.filter(
  //       (customers) =>
  //         customers.first_name
  //           ?.toLowerCase()
  //           ?.includes(search?.toLowerCase()) ||
  //         customers.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         customers.phone_number
  //           ?.toLowerCase()
  //           ?.includes(search?.toLowerCase()) ||
  //         customers.country_code
  //           ?.toLowerCase()
  //           ?.includes(search?.toLowerCase()) ||
  //         customers.email?.toLowerCase()?.includes(search?.toLowerCase())
  //     );
  //   }
  //   setTotalItems(computedComments.length);

  //   //Sorting comments
  //   if (sorting.field) {
  //     const reversed = sorting.order === 'asc' ? 1 : -1;
  //     computedComments = computedComments.sort(
  //       (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  //     );
  //   }

  //   //Current Page slice
  //   return computedComments.slice(
  //     (currentPage - 1) * limit,
  //     (currentPage - 1) * limit + limit
  //   );
  // }, [currentPage, search, sorting, limit, entity]);

  useEffect(() => {
    getUserList();

    document.getElementById('page-loader').style.display = 'none';
    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, [currentPage, search, sorting, limit]);

  const getUserList = () => {
    setLoading(true)
    const myurl = 'https://api.teleportanything.com/api/admin/users-list';
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('status', filterData.status);
    bodyFormData.append('page', currentPage);
    bodyFormData.append('limit', limit);
    bodyFormData.append('search', search);

    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then(async (response) => {
        setDisable(false);
        if (response?.data?.sucecess) {
          await setEntity(response?.data?.data?.list ?? []);
          await setTotalItems(response?.data?.data?.totalItems ?? 0);
        } else {
          toast.error('Something went wrong');
        }
        setLoading(false)

      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error("Errors", error);
        setLoading(false)
      });
  };

  const handleStatusUser = (id, status) => {

    if (window.confirm("Do you want to change status this user?")) {
      const myurl = "https://api.teleportanything.com/api/admin/user-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('user_id', id);
      bodyFormData.append('status', status == 1 ? 0 : 1);
      setLoading(true)
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        if (response.data.sucecess == true) {
          getUserList();
          toast.success(response.data.message);
        }
        setLoading(false)

      }).catch((error) => {
        console.error("Errors", error);
        setLoading(false)
      })
    }
  }

  const handleStatusDelete = (id) => {

    if (window.confirm("Do you want to delete this user?")) {
      const myurl = "https://api.teleportanything.com/api/admin/user-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('user_id', id);
      bodyFormData.append('status', 2);
      setLoading(true)

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        if (response.data.sucecess == true) {
          getUserList();
          toast.success("User Delete Successfully");
        }
        setLoading(false)
      }).catch((error) => {
        console.error("Errors", error);
        setLoading(false)
      })
    }
  }

  const [disable, setDisable] = useState(false);
  const [filterData, setFilterData] = useState({
    status: ''
  });
  const handleInput = (e) => {
    ;
    const basicData = { ...filterData };
    basicData[e.target.name] = e.target.value;
    setFilterData(basicData);
  }
  const handleSearch = (e) => {
    setDisable(true);
    getUserList();
  }

  const handleReset = () => {
    window.location.reload();
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              User Management
            </li>
          </ol>
          <h1 className="page-header">Users Management</h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="col-12 text-center">
                <h6 style={{ textAlign: "left" }}>Filter For Users Management:</h6>
                <div className="row">

                  <div className="col-md-3 form-group">
                    <select className="form-control" name="status" onChange={handleInput} value={filterData.status}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                      <option value="2">Deleted</option>
                    </select>
                  </div>

                  <div className="col-md-3" style={{ textAlign: "left" }}>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer" }} disabled={disable} onClick={handleSearch} >{disable ? 'Processing...' : 'Search'}</button>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleReset} >Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <br />

            <div
              style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '20px',
                marginTop: '10px',
              }}
            >
              <div className="row w-100">
                <div className="mb-3 col-12 text-center">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                      <div className="ml-0">
                        <div className="d-flex">
                          <h5 className="mt-2 mr-1">Search: </h5>
                          <Search
                            onSearch={(value) => {
                              setSearch(value);
                              setCurrentPage(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                      <div
                        style={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: '300',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                        className="align-self-center"
                      >
                        <b>Rows per page :&nbsp;</b>
                      </div>
                      <div className="align-self-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            style={{
                              cursor: 'auto',
                              backgroundColor: 'white',
                              borderColor: '#d5dbe0',
                              paddingBottom: '3px',
                              paddingTop: '3px',
                            }}
                          >
                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {limit !== 10 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(10);
                                  }}
                                >
                                  10
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 20 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(20);
                                  }}
                                >
                                  20
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 30 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(30);
                                  }}
                                >
                                  30
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 50 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(50);
                                  }}
                                >
                                  50
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <TableHeader
                              headers={userHeader}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            />
                          </thead>
                          <tbody>
                          {Loading ? (
                            <tr>
                            <td colSpan={13}>loading...</td></tr>
                          ) : (
                            entity.map((e, i) => (
                              <tr>
                                <>
                                  <td>{i + 1}</td>
                                  <td>
                                    {e?.image?.match('undefined') !=
                                      'undefined' ? (
                                      e?.image != null ? (
                                        <img
                                          src={e.image}
                                          width="70px"
                                          height="60px"
                                          alt="UserImg"
                                        />
                                      ) : (
                                        <img
                                          src="./assets/img/icon/customer.png"
                                          width="50px"
                                          height="50px"
                                          alt="users"
                                        />
                                      )
                                    ) : (
                                      <img
                                        src="./assets/img/icon/customer.png"
                                        width="50px"
                                        height="50px"
                                        alt="users"
                                      />
                                    )}
                                  </td>
                                  <td>{e?.first_name}</td>
                                  <td>{e?.last_name}</td>
                                  <td>{e?.email}</td>
                                  <td>
                                    {e?.phone_number ? e?.phone_number : 'N/A'}
                                  </td>
                                  {/* <td>{e?.gender}</td> */}
                                  <td>{e?.city ? e?.city : 'N/A'}</td>

                                  <td>{e?.country ? e?.country : 'N/A'}</td>
                                  <td>{e?.createdAt ? moment(e?.createdAt).format("YYYY/MM/DD hh:mm:ss a") : "-"}</td>

                                  <td>
                                    <i
                                      className="fa fa-eye edit"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        navigate('/users-details', { state: e })
                                      }
                                    ></i>
                                  </td>
                                  <td>
                                    <div class="custom-control custom-switch custom-switch-sm" style={{ paddingLeft: '10px' }}>
                                      <input type="checkbox" class="custom-control-input" id={e.id} data-id={e.id} checked={e.status == 1 ? 'checked' : ''} onClick={() => handleStatusUser(e.id, e.status)} />
                                      <label class="custom-control-label" for={e.id}></label>
                                    </div>

                                    <i
                                      className="fa fa-trash del-icon-list"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleStatusDelete(e.id)}
                                    ></i>

                                  </td>
                                </>
                              </tr>
                            ))
                          )}
                          </tbody>
                        </Table>
                        {entity.length == 0 ? (
                          <div className="row d-flex justify-content-center w-100">
                            <div className="mx-auto  d-flex justify-content-center w-100">
                              <img
                                src="./assets/img/icon/no-location.png"
                                className="form-img__img-preview"
                                style={{ width: '100px', height: '100px' }}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                    style={{
                      overflowX: 'auto',
                    }}
                  >
                    <Pagination
                      total={totalItems}
                      itemsPerPage={limit}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          
        </div>

        <Footer />
      </div>
    </>
  );
}
