import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";

const CmsManagement = () => {
  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    getCmsContent();
  }, []);

  const [termsCondition, setTermsCondition] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [disable1, setDisable1] = useState(false);
  const [disable2, setDisable2] = useState(false);
  const [disable3, setDisable3] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alert2, setAlert2] = useState(false);
  const [alert3, setAlert3] = useState(false);
  const [errors, setErrors] = useState({});

  const getCmsContent = () => {
    const myurl = `https://api.teleportanything.com/api/admin/get-cms`;
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "none" },
    })
      .then((response) => {
        if (response?.data?.success === true) {
          let res = response?.data?.data;
          setAboutUs(res[0]?.about_us);
          setTermsCondition(res[0]?.terms_conditions);
          setPrivacyPolicy(res[0]?.privacy_policy);
        }
      })
      .catch((error) => {
        console.error("Errors", error);
      });
  };

  const handleTermsCondition = (e, editor) => {
    setTermsCondition(editor.getData());
  };

  const handlePrivacyPolicy = (e, editor) => {
    setPrivacyPolicy(editor.getData());
  };

  const handleAboutUs = (e, editor) => {
    setAboutUs(editor.getData());
  };

  const handleSubmitAboutUs = (e) => {
    e.preventDefault();
    if (aboutUs) {
      setDisable2(true);
      const myurl = `https://api.teleportanything.com/api/admin/update-about-us`;
      var bodyFormData = new FormData();
      bodyFormData.append("auth_code", 'Teleport#Usr$&$Driver#MD');
      bodyFormData.append("about_us", aboutUs);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.success === true) {
            setDisable2(false);
            setAlert2(true);
            getCmsContent();
          } else {
            setDisable2(false);
          }
        })
        .catch((error) => {
          console.error("Errors", error);
        });
    } else {
      setErrors({ ...errors, about_err: "Please Enter About Us" });
    }
  };

  const handleSubmitTermsCondition = (e) => {
    e.preventDefault();
    if (termsCondition) {
      setDisable1(true);
      const myurl = `https://api.teleportanything.com/api/admin/update-terms-condition`;
      var bodyFormData = new FormData();
      bodyFormData.append("auth_code", 'Teleport#Usr$&$Driver#MD');
      bodyFormData.append("term_condition", termsCondition);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.success === true) {
            setDisable1(false);
            setAlert2(true);
            getCmsContent();
          } else {
            setDisable1(false);
          }
        })
        .catch((error) => {
          console.error("Errors", error);
        });
    } else {
      setErrors({ ...errors, about_err: "Please Enter About Us" });
    }
  };

  const handleSubmitPrivacyPolicy = (e) => {
    e.preventDefault();
    if (privacyPolicy) {
      setDisable3(true);
      const myurl = `https://api.teleportanything.com/api/admin/update-privacy-policy`;
      var bodyFormData = new FormData();
      bodyFormData.append("auth_code", 'Teleport#Usr$&$Driver#MD');
      bodyFormData.append("privacy_policy", privacyPolicy);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.success === true) {
            setDisable3(false);
            setAlert2(true);
            getCmsContent();
          } else {
            setDisable3(false);
          }
        })
        .catch((error) => {
          console.error("Errors", error);
        });
    } else {
      setErrors({ ...errors, about_err: "Please Enter About Us" });
    }
  };

  return (
    <>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active">CMS Management</li>
          </ol>
          <h1 className="page-header">CMS Management</h1>
          <div className="card">
            <div className="card-body">
              <div className="row" style={{ paddingLeft: "12px" }}>
                <h4>Terms & Conditions</h4>
                <br />
              </div>
              <CKEditor
                id="editor"
                name="termsCondition"
                editor={ClassicEditor}
                data={termsCondition}
                onChange={(e, editor) => {
                  handleTermsCondition(e, editor);
                }}
                style={{ borderRadius: "15px" }}
              />
              <div className="text-danger">{errors.terms_err}</div>
              <button
                type="button"
                className="btn btn-md btn-success m-t-30"
                style={{ fontSize: "15px" }}
                disabled={disable1}
                onClick={handleSubmitTermsCondition}
              >
                {disable1 ? "Processing..." : "Save"}
              </button>
            </div>
          </div>
          <div className="card" style={{ marginTop: "30px" }}>
            <div className="card-body">
              <div className="row" style={{ paddingLeft: "12px" }}>
                <h4>About Us</h4>
                <br />
              </div>
              <CKEditor
                id="editor"
                name="aboutUs"
                editor={ClassicEditor}
                data={aboutUs}
                onChange={(e, editor) => {
                  handleAboutUs(e, editor);
                }}
                style={{ borderRadius: "15px" }}
              />
              <div className="text-danger">{errors.about_err}</div>
              <button
                type="button"
                className="btn btn-md btn-success m-t-30"
                style={{ fontSize: "15px" }}
                disabled={disable2}
                onClick={handleSubmitAboutUs}
              >
                {disable2 ? "Processing..." : "Save"}
              </button>
            </div>
          </div>

          <div className="card" style={{ marginTop: "30px" }}>
            <div className="card-body">
              <div className="row" style={{ paddingLeft: "12px" }}>
                <h4>Privacy Policy</h4>
                <br />
              </div>
              <CKEditor
                id="editor"
                name="aboutUs"
                editor={ClassicEditor}
                data={privacyPolicy}
                onChange={(e, editor) => {
                  handlePrivacyPolicy(e, editor);
                }}
                style={{ borderRadius: "15px" }}
              />
              <div className="text-danger">{errors.privacy_policy_err}</div>
              <button
                type="button"
                className="btn btn-md btn-success m-t-30"
                style={{ fontSize: "15px" }}
                disabled={disable3}
                onClick={handleSubmitPrivacyPolicy}
              >
                {disable3 ? "Processing..." : "Save"}
              </button>
            </div>
          </div>

        </div>
        {alert === true ? (
          <SweetAlert
            success
            title="Terms & Condition Updated Successfully!"
            onConfirm={() => {
              setAlert(false);
            }}
          />
        ) : (
          ""
        )}
        {alert2 === true ? (
          <SweetAlert
            success
            title="About Us Updated Successfully!"
            onConfirm={() => {
              setAlert2(false);
            }}
          />
        ) : (
          ""
        )}
        {alert3 === true ? (
          <SweetAlert
            success
            title="Privacy Policy Updated Successfully!"
            onConfirm={() => {
              setAlert3(false);
            }}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </>
  );
};

export default CmsManagement;
