import { TableHeader, Pagination, Search } from '../Table';
import React, { useState, useEffect, useMemo } from 'react';
import Loader from '../include/Loader';
import Menu from '../include/Menu';
import Footer from '../include/Footer';
import { Dropdown, Table } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HistoryIcon from '@material-ui/icons/History';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../include/Spinner';
import moment from 'moment/moment';

export default function ShowJobsList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [limit, setlimit] = useState(10);
  const [Loading, setLoading] = useState(true);

  const userHeader = [
    {
      name: 'Sr. No.',
      field: 'id',
      sortable: false,
    },
    {
      name: 'Customer',
      // field: "",
      sortable: false,
    },
    {
      name: 'Driver',
      // field: "",
      sortable: false,
    },
    {
      name: 'Item Name',
      // field: "",
      sortable: false,
    },
    {
      name: 'Pickup Address',
      // field: "",
      sortable: false,
    },
    {
      name: 'Drop Address',
      // field: "",
      sortable: false,
    },
    {
      name: 'Item Value',
      // field: "to",
      sortable: false,
    },

    {
      name: 'Delivery Request Amount',
      sortable: false,
    },
    {
      name: 'Status',
      sortable: false,
    },
    {
      name: 'Created At',
      sortable: false,
    },
    {
      name: 'Completed At',
      sortable: false,
    },
    {
      name: 'Details',
      sortable: false,
    },
    {
      name: 'Action',
      sortable: false,
    },
  ];

  // const commentsData = useMemo(() => {
  //   let computedComments = entity;

  //   if (search) {

  //     computedComments = computedComments.filter(
  //       (jobs) =>
  //         (jobs?.user_id?.first_name + " " + jobs?.user_id?.last_name)?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         (jobs?.user_id?.last_name + " " + jobs?.user_id?.first_name)?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         (jobs?.hire_driver_id?.first_name + " " + jobs?.hire_driver_id?.last_name)?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         (jobs?.hire_driver_id?.last_name + " " + jobs?.hire_driver_id?.first_name)?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs?.user_id?.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.customer?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.driver?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.item_description?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.pickup_address?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.drop_address?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.item_value?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.delivery_request_amount?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //         jobs.status.toString()?.toLowerCase()?.includes(search?.toLowerCase())

  //     );
  //   }

  //   setTotalItems(computedComments.length);

  //   //Sorting comments
  //   if (sorting.field) {
  //     const reversed = sorting.order === 'asc' ? 1 : -1;
  //     computedComments = computedComments.sort(
  //       (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  //     );
  //   }

  //   //Current Page slice
  //   return computedComments?.slice(
  //     (currentPage - 1) * limit,
  //     (currentPage - 1) * limit + limit
  //   );
  // }, [currentPage, search, sorting, limit, entity]);


  useEffect(() => {
    getDriverList();
    document.getElementById('page-loader').style.display = 'none';
    var element = document.getElementById('page-container');
    element.classList.add('show');
  }, [currentPage, search, sorting, limit]);

  const getDriverList = () => {
    setLoading(true)
    const myurl = 'https://api.teleportanything.com/api/admin/jobs-list';
    //const myurl = 'http://localhost:3000/api/admin/jobs-list';
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('status', filterData.status);
    bodyFormData.append('page', currentPage);
    bodyFormData.append('limit', limit);
    bodyFormData.append('search', search);
    axios({
      method: 'post',
      url: myurl,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then(async (response) => {
        setDisable(false);

        if (response?.data?.sucecess) {
          let data = await response?.data?.data?.list?.map((e, i) => {
            let startFrom = currentPage === 1 ? 1 : ((currentPage - 1) * 10) + 1;
            return { ...e, sr_no: (startFrom + i) };
          });
          setEntity(data ?? []);
          setTotalItems(response?.data?.data?.totalItems ?? 0);
        } else {
          toast.error('Something went wrong');
        }
        setLoading(false)
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.log("Errors", error);
        setLoading(false)
      });
  };


  const handleStatusDriver = (id, status) => {

    if (window.confirm("Do you want to change status this job?")) {
      const myurl = "https://api.teleportanything.com/api/admin/job-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('job_id', id);
      bodyFormData.append('status', status == 1 ? 0 : 1);
      setLoading(true)
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        if (response.data.sucecess == true) {
          getDriverList();
          toast.success(response.data.message);
        }
        setLoading(false)

      }).catch((error) => {
        console.error("Errors", error);

        setLoading(false)
      })
    }
  }

  const handleStatusDelete = (id) => {

    if (window.confirm("Do you want to delete this job?")) {
      const myurl = "https://api.teleportanything.com/api/admin/job-status";
      //const myurl = "http://localhost:3000/api/admin/job-status";
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('job_id', id);
      bodyFormData.append('status', 2);
      setLoading(true)

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
        if (response.data.sucecess == true) {
          getDriverList();
          toast.success("Job Delete Successfully");
        }
        setLoading(false)

      }).catch((error) => {
        console.error("Errors", error);
        setLoading(false)

      })
    }
  }

  const [disable, setDisable] = useState(false);
  const [filterData, setFilterData] = useState({
    status: ''
  });
  const handleInput = (e) => {
    ;
    const basicData = { ...filterData };
    basicData[e.target.name] = e.target.value;
    setFilterData(basicData);
  }
  const handleSearch = (e) => {
    setDisable(true);
    getDriverList();
  }

  const handleReset = () => {
    window.location.reload();
  }

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              <a href="javascript:;">Home</a>
            </li>
            <li className="breadcrumb-item active currentPath">
              Jobs Management
            </li>
          </ol>
          <h1 className="page-header">Jobs Management</h1>

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
            }}
          >
            <div className="row w-100">
              <div className="col-12 text-center">
                <h6 style={{ textAlign: "left" }}>Filter For Jobs Management:</h6>
                <div className="row">

                  <div className="col-md-3 form-group">
                    <select className="form-control" name="status" onChange={handleInput} value={filterData.status}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                      <option value="2">Draft</option>
                      <option value="3">Completed</option>
                    </select>
                  </div>

                  <div className="col-md-3" style={{ textAlign: "left" }}>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer" }} disabled={disable} onClick={handleSearch} >{disable ? 'Processing...' : 'Search'}</button>
                    <button type='button' className='btn btn-md btn-primary m-t-2' style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleReset} >Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <br />

          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
              marginTop: '10px',
            }}
          >
            <div className="row w-100">
              <div className="mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                    <div className="ml-0">
                      <div className="d-flex">
                        <h5 className="mt-2 mr-1">Search: </h5>
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: '300',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className="align-self-center"
                    >
                      <b>Rows per page :&nbsp;</b>
                    </div>
                    <div className="align-self-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          style={{
                            cursor: 'auto',
                            backgroundColor: 'white',
                            borderColor: '#d5dbe0',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          {limit}&nbsp;<i className="fa fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {limit !== 10 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(10);
                                }}
                              >
                                10
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 20 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(20);
                                }}
                              >
                                20
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 30 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(30);
                                }}
                              >
                                30
                              </Dropdown.Item>
                            </>
                          ) : null}

                          {limit !== 50 ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setlimit(50);
                                }}
                              >
                                50
                              </Dropdown.Item>
                            </>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <TableHeader
                            headers={userHeader}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                        </thead>
                        <tbody>
                          {Loading ? (
                            <tr>
                              <td colSpan={13}>loading...</td></tr>
                          ) : (
                            entity.map((e, i) => (
                              <tr>
                                <>
                                  <td>{e?.sr_no}</td>
                                  <td>{e?.user_id?.first_name ? ((e?.user_id?.first_name || "") + " " + (e?.user_id?.last_name || "")) : "-"}</td>
                                  <td>{e?.hire_driver_id?.first_name ? ((e?.hire_driver_id?.first_name || "") + " " + (e?.hire_driver_id?.last_name || "")) : "-"}</td>
                                  <td>{e?.item_description}</td>
                                  <td>{e?.pickup_address}</td>
                                  <td>{e?.drop_address}</td>
                                  <td>{e?.item_value}</td>
                                  <td>{e?.delivery_request_amount}</td>
                                  <td>{e?.status}</td>
                                  <td>{moment(e?.createdAt).format("YYYY/MM/DD hh:mm:ss a")}</td>
                                  <td>{e?.completed_At ? moment(e?.completed_At).format("YYYY/MM/DD hh:mm:ss a") : "-"}</td>
                                  <td>
                                    <i
                                      className="fa fa-eye edit"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        navigate('/jobs-details', {
                                          state: e,
                                        })
                                      }
                                    ></i>
                                  </td>
                                  <td>
                                    {/* <div class="custom-control custom-switch custom-switch-sm" style={{paddingLeft:'10px'}}>
                                    <input type="checkbox" class="custom-control-input" id={e.id} data-id={e.id} checked={ e.status == 1 ? 'checked' : '' } onClick={() => handleStatusDriver(e.id,e.status)} />
                                    <label class="custom-control-label" for={e.id}></label>
                                  </div> */}

                                    <i
                                      className="fa fa-trash "
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleStatusDelete(e.id)}
                                    ></i>

                                  </td>
                                </>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                      {entity.length == 0 ? (
                        <div className="row d-flex justify-content-center w-100">
                          <div className="mx-auto  d-flex justify-content-center w-100">
                            <img
                              src="./assets/img/icon/no-location.png"
                              className="form-img__img-preview"
                              style={{ width: '100px', height: '100px' }}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                  style={{
                    overflowX: 'auto',
                  }}
                >
                  <Pagination
                    total={totalItems}
                    itemsPerPage={limit}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
